@import "_variables.scss";
@import "_typography.scss";


// Navigation
.ckb-navigation {
    z-index: 100;
    position: absolute;
    scroll-behavior: smooth;
    
  
    .nav-top {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      padding: 12px 0px;

      @include breakpoint(lg) {
        padding: 20px 0px;
      }

      &.light {
        a {
            color: $text-dark;

            &:hover {
                path {
                    fill: transparent;
                    stroke: $text-dark;
                }
            }

        }
        path {
            fill: $text-dark;
        }
    }
    }


    
    
    .logo-top {
      display: flex;
      flex-direction: column;
      z-index: 1000;
      padding-left: 16px;
  
      @include breakpoint(lg) {
        padding-left: 4vw;
      }

      p {
        margin: 0;
      }

      a {
        line-height: 0;
      }
    }

    .topmenu {
      display: flex;
      top: 0;
      right: 0;
      z-index: 1000;
      padding-right: 16px;
    
      @include breakpoint(lg) {
        padding-right: 4vw;
      }
    
      a {
        color: $text-dark;
        text-decoration: none;
        font-size: $text-base;
      }

    }
    .logo_top p, .topmenu p {
      margin-top: 0;
    }

  }



.dark {
  .logo {
    path {
      fill: $text-light;
    }
  }
}
.logo {
  width: 105px;
  height: 16px;

  @include breakpoint(md) {
    width: 132px;
    height: 20px;
  }
  @include breakpoint(lg) {
    width: 145px;
    height: 22px;
  }
  @include breakpoint(xl) {
    width: 158px;
    height: 24px;
  
  }

  path {
    fill: $text-dark;
  }
}

.ckb-logo {
  &:hover {
    .logo {
      animation-name: logohover;
      animation-duration: 1s;
      animation-fill-mode: both;
      transform-origin: center;
      animation-iteration-count: 1;

      path {
        stroke-width: 1;
        stroke: $text-dark;
        stroke-linecap: round;
        fill: transparent;
      }
    }
  }
}

.dark {
  .ckb-logo {
    &:hover {
      .logo {
  
        path {
          stroke: $text-light;
        }
      }
    }
  }
}


.topmenu {
  &:hover {
    a {
      animation-name: logohover;
      animation-duration: 1s;
      animation-fill-mode: both;
      transform-origin: center;
      animation-iteration-count: 1;
    }
        
    }
}