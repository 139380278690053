@media (width >= 1024px) {
  h1 {
    margin: 20px 0;
  }
}

@media (width >= 1024px) {
  h2 {
    margin: 20px 0;
  }
}

@media (width >= 1024px) {
  h3 {
    margin: 20px 0;
  }
}

@media (width >= 1024px) {
  h4 {
    margin: 20px 0;
  }
}

@media (width >= 1400px) {
  h4 {
    margin: 20px 0;
  }
}

.ckb-navigation {
  z-index: 100;
  scroll-behavior: smooth;
  position: absolute;
}

.ckb-navigation .nav-top {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  display: flex;
  position: fixed;
}

@media (width >= 1024px) {
  .ckb-navigation .nav-top {
    padding: 20px 0;
  }
}

.ckb-navigation .nav-top.light a {
  color: #1b201b;
}

.ckb-navigation .nav-top.light a:hover path {
  fill: #0000;
  stroke: #1b201b;
}

.ckb-navigation .nav-top.light path {
  fill: #1b201b;
}

.ckb-navigation .logo-top {
  z-index: 1000;
  flex-direction: column;
  padding-left: 16px;
  display: flex;
}

@media (width >= 1024px) {
  .ckb-navigation .logo-top {
    padding-left: 4vw;
  }
}

.ckb-navigation .logo-top p {
  margin: 0;
}

.ckb-navigation .logo-top a {
  line-height: 0;
}

.ckb-navigation .topmenu {
  z-index: 1000;
  padding-right: 16px;
  display: flex;
  top: 0;
  right: 0;
}

@media (width >= 1024px) {
  .ckb-navigation .topmenu {
    padding-right: 4vw;
  }
}

.ckb-navigation .topmenu a {
  color: #1b201b;
  font-size: clamp(17px, .522876vw + 14.9608px, 25px);
  text-decoration: none;
}

.ckb-navigation .logo_top p, .ckb-navigation .topmenu p {
  margin-top: 0;
}

.dark .logo path {
  fill: #fff;
}

.logo {
  width: 105px;
  height: 16px;
}

@media (width >= 850px) {
  .logo {
    width: 132px;
    height: 20px;
  }
}

@media (width >= 1024px) {
  .logo {
    width: 145px;
    height: 22px;
  }
}

@media (width >= 1400px) {
  .logo {
    width: 158px;
    height: 24px;
  }
}

.logo path {
  fill: #1b201b;
}

.ckb-logo:hover .logo {
  transform-origin: center;
  animation-name: logohover;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.ckb-logo:hover .logo path {
  stroke-width: 1px;
  stroke: #1b201b;
  stroke-linecap: round;
  fill: #0000;
}

.dark .ckb-logo:hover .logo path {
  stroke: #fff;
}

.topmenu:hover a {
  transform-origin: center;
  animation-name: logohover;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

h1 {
  margin: 10px 0;
  font-family: El Messiri, sans-serif;
  font-size: clamp(33px, 3.0719vw + 21.0196px, 80px);
  font-weight: 400;
}

@media (width >= 1024px) {
  h1 {
    margin: 20px 0;
  }
}

h2 {
  margin: 10px 0;
  font-family: El Messiri, sans-serif;
  font-size: clamp(26px, 2.22222vw + 17.3333px, 60px);
  font-weight: 400;
}

@media (width >= 1024px) {
  h2 {
    margin: 20px 0;
  }
}

h3 {
  margin: 10px 0;
  font-family: El Messiri, sans-serif;
  font-size: clamp(22px, 1.30719vw + 16.902px, 42px);
}

@media (width >= 1024px) {
  h3 {
    margin: 20px 0;
  }
}

h4 {
  margin: 10px 0;
  font-size: clamp(19px, .718954vw + 16.1961px, 30px);
  font-weight: 300;
}

@media (width >= 1024px) {
  h4 {
    margin: 20px 0;
  }
}

@media (width >= 1400px) {
  h4 {
    margin: 20px 0;
  }
}

.muted {
  opacity: .6;
}

.small {
  font-size: clamp(14px, .261438vw + 12.9804px, 18px);
}

.xsmall {
  font-size: clamp(12px, .130719vw + 11.4902px, 14px);
}

.nomargin {
  margin: 0;
}

.footer {
  max-width: 1920px;
  margin: 15vh auto;
  padding: 0 16px;
  transition: all .3s;
}

@media (width >= 850px) {
  .footer {
    margin: 20vh auto;
  }
}

@media (width >= 1024px) {
  .footer {
    padding: 0 8vw;
  }
}

.footer .wrapper {
  color: #fff;
  background: #1b201b;
  border-radius: 8px;
  flex-direction: column;
  gap: 40px;
  padding: 40px 16px;
  display: flex;
}

@media (width >= 544px) {
  .footer .wrapper {
    padding: 24px;
  }
}

@media (width >= 850px) {
  .footer .wrapper {
    padding: 40px;
  }
}

@media (width >= 1024px) {
  .footer .wrapper {
    padding: 60px;
  }
}

@media (width >= 1400px) {
  .footer .wrapper {
    padding: 80px;
  }
}

.footer .wrapper a {
  color: #fff;
}

.footer .content {
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  display: flex;
}

@media (width >= 850px) {
  .footer .content {
    flex-direction: row;
    align-items: center;
    gap: 5%;
  }
}

.footer .content .text p {
  margin: 10px 0;
}

@media (width >= 850px) {
  .footer .content .text {
    width: 50%;
  }

  .footer .content .card {
    width: 45%;
    max-width: 500px;
  }
}

.footer .content .card .c-header {
  background-color: #5b6f51;
  border-radius: 8px 8px 0 0;
  align-items: center;
  gap: 12px;
  padding: 12px;
  display: flex;
}

@media (width >= 1024px) {
  .footer .content .card .c-header {
    gap: 16px;
    padding: 12px;
  }
}

@media (width >= 1400px) {
  .footer .content .card .c-header {
    gap: 24px;
    padding: 24px;
  }
}

.footer .content .card .c-header img {
  border-radius: 200px;
  width: 30%;
  max-width: 100px;
  margin: 0;
}

@media (width >= 1024px) {
  .footer .content .card .c-header img {
    max-width: 160px;
  }
}

.footer .content .card .c-footer {
  background-color: #414f3a;
  border-radius: 0 0 8px 8px;
  padding: 12px;
}

@media (width >= 1024px) {
  .footer .content .card .c-footer {
    padding: 16px;
  }
}

@media (width >= 1400px) {
  .footer .content .card .c-footer {
    padding: 16px 24px;
  }
}

.footer .content .card .c-footer .highlights {
  color: #fff;
  align-items: center;
  gap: 16px;
  align-items: inherit;
  border-radius: 8px;
  flex-direction: row;
  font-size: clamp(12px, .130719vw + 11.4902px, 14px);
  display: flex;
}

@media (width >= 1400px) {
  .footer .content .card .c-footer .highlights {
    gap: 16px;
  }
}

.footer .content .card .c-footer .highlights .item {
  align-items: center;
  gap: 8px;
  width: fit-content;
  display: flex;
}

.footer .content .card .c-footer .highlights .item img {
  width: 18px;
  margin: 0;
}

@media (width >= 1400px) {
  .footer .content .card .c-footer .highlights .item img {
    width: 24px;
  }
}

.footer hr {
  border: 1px solid #414f3a;
  width: 100%;
  margin: 0;
}

.footer .links {
  text-align: center;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: 8px;
  padding-top: 12px;
  display: flex;
}

@media (width >= 1024px) {
  .footer .links {
    flex-direction: row;
    padding-top: 16px;
  }
}

.dark .footer .wrapper {
  color: #1b201b;
  background-color: #fff;
}

.dark .footer .wrapper a {
  color: #1b201b;
}

.dark .footer .wrapper .c-header {
  background-color: #e1ebdb;
}

.dark .footer .wrapper .c-footer {
  background-color: #414f3a;
}

.dark .footer .wrapper hr {
  border: 1px solid #dddfdc;
}

.dark .footer h4 {
  opacity: .6;
}
/*# sourceMappingURL=index.94e05072.css.map */
